import React, {FC} from "react";
import styled from "styled-components";
import Flex from "./Flex";

const LitePopup: FC<{
    setPopupState: (state: boolean) => void
}> = ({setPopupState}) => {
    const goLitePromotionPage = () => {
        window.location.href = "https://www.matholic.com/promotion";
    }
    const isMobile = window.innerWidth < 768;
    return <Wrapper>
        <Content>
            <CloseBtn onClick={() => setPopupState(false)}>X</CloseBtn>
            <ImageWrapper>
                <img src={isMobile ? "assets/imgs/lite/lite_popup_mobile.png" : "assets/imgs/lite/lite_popup.png"} alt="LITE 무료체험 이미지"/>
            </ImageWrapper>
            <ButtonArea onClick={() => goLitePromotionPage()}/>
        </Content>
    </Wrapper>
}

export default LitePopup;
const Wrapper = styled(Flex)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 100;
    justify-content: center;
    align-items: center;
`
const Content = styled(Flex)`
    position: relative;
`
const ImageWrapper = styled(Flex)`
    > img {
        width: 100%;
    }
`
const CloseBtn = styled(Flex)`
    height: 50px;
    justify-content: center;
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    top: 0;
    width: 45px;
    left: calc(100% - 45px);
    cursor: pointer;
    text-align: center;
`
const ButtonArea = styled(Flex)`
    width: 100%;
    height: 100px;
    justify-content: center;
    margin-top: 24px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
`
