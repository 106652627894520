import React, {useState} from "react";
import "./App.css";
import Flex from "./common/components/Flex";
import styled from "styled-components";
import HomeScreen from "./screen/home/HomeScreen";
import {Span, Wrapper} from "./common/components/common";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import FreeTrialView from "./screen/freeTrial/FreeTrialView";
import CounsellingSuccess from "./screen/freeTrial/CounsellingSuccess";
import Success from "./screen/freeTrial/Success";
import {FiExternalLink} from "react-icons/fi";
import LitePopup from "./common/components/LitePopup";

function Main() {
    const [popupState, setPopupState] = useState(false)
    if (window.location.hash === '#/freeTrial')
        window.location.href = 'freeTrial'
    return <Flex className="App" horizontalCenter flex={1}>
        {/*Header*/}
        <Flex horizontalCenter
              style={{
                  backgroundColor: "#ffffff", height: 80, width: "100%",
                  /*position: "sticky", top: 0*/
              }}>
            <Flex flex={1} style={{maxWidth: 1320, width: "100%"}}>
                <StyledTopBar row spaceBetween verticalCenter style={{margin: 20}}>
                    <Logo src={"assets/logo.png"}/>
                    <Flex row>
                        <Button>
                            <a href={"https://blog.naver.com/PostList.naver?blogId=pocketmath"}
                               target={"_blank"}
                               style={{textDecoration: 'none'}}>블로그</a>
                            <FiExternalLink style={{marginLeft: 4}}/>
                        </Button>
                        <Button><a href={"https://dia.pocketmath.co.kr"}
                                   style={{textDecoration: 'none'}}>로그인</a></Button>
                        <Button trialButton onClick={() => setPopupState(true)}><span>무료로 시작하기</span></Button>
                    </Flex>
                </StyledTopBar>
            </Flex>
        </Flex>

        <HomeScreen/>

        <FixFreeTrialBanner center row>
            <FreeTrialButton
                onClick={() => setPopupState(true)}
                style={{
                    backgroundColor: 'black', padding: "10px 20px 10px 20px",
                }}>
                <Span size={24} color={'white'} bold>무료로 시작하기</Span>
            </FreeTrialButton>

        </FixFreeTrialBanner>

        {/*<FixBanner center row*/}
        {/*           onClick={() => window.open("https://www.matholic.com/trialExam")}*/}
        {/*>*/}
        {/*    <img src={"assets/imgs/trialExam/exam_banner.gif"}*/}
        {/*         style={{height: "100%", maxWidth: 1320, width: "100%", objectFit: "contain"}}/>*/}
        {/*</FixBanner>*/}


        {/*Footer*/}
        <Wrapper bgColor={"#e8e8e8"}>
            <Flex style={{width: 'auto', fontSize: 11, padding: 40}} center>
                    <span>
                    ㈜매쓰홀릭 | 서울 서초구 서초대로74길 45, 엔데버타워 12층 | 사업자등록번호 : 119-86-42991 | 통신판매업신고 : 2020-서울서초-3247 | 대표이사 : 강지훈, 김영희
                </span>
                <span>
                    {"고객센터 : 1588-9034 | 대표 이메일 : help@pocketmath.co.kr | "}
                    <a href={"https://dia.pocketmath.co.kr/service-privacy.html"} target={"_blank"}>  개인정보처리방침  </a>
                        <span>{" | "}
                        </span>
                        <a href={"https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1198642991"}
                           target={"_blank"}>사업자 정보 확인</a>
                </span>
                <span>
                    Copyright © Matholic. All Rights Reserved
                </span>
            </Flex>
        </Wrapper>
        {popupState && <LitePopup setPopupState={setPopupState}/>}
    </Flex>
}


function App() {
    return (
        <Router>
            <Switch>
                <Route exact path={"/"} component={Main}/>
                <Route exact path={"/freeTrial"} component={FreeTrialView}/>
                <Route exact path={"/counsellingSuccess"} component={CounsellingSuccess}/>
                <Route exact path={"/success"} component={Success}/>
            </Switch>
        </Router>
    );
}

const FreeTrialButton = styled.button`
    border: 3px solid white;
    padding: 10px 20px;
    cursor: pointer;
    background-color: transparent;
`

const FixedTrialButton = styled(Flex)`
    //padding: 6px 16px;
    width: 100px;
    height: 100px;
    background-color: #77B6FF;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 100%; //40px;
    box-shadow: 1px 5px 15px -4px #000000;
`
const Button = styled.button<{ trialButton?: boolean }>`
    background-color: ${({trialButton}) => trialButton ? "#E2644B" : "#ffffff"};
    border: 0;
    height: 42px;
    min-width: 40px;
    margin-left: 20px;
    padding: ${({trialButton}) => trialButton ? "0px 20px 0px 20px" : 0};
    border-radius: 3px;
    cursor: pointer;

    span {
        color: ${({trialButton}) => trialButton ? "#ffffff" : "#3A6BD1"};
        font-weight: bold;
        font-family: "Noto Sans KR"
    }

    @media (max-width: 480px) {
        min-width: 20px;
        font-size: 12px;
        padding: ${({trialButton}) => trialButton ? "0px 6px 0px 6px" : 0};
    }

`

const Logo = styled.img`

    @media (max-width: 480px) {
        width: 30%;
        object-fit: contain;
        padding: 0;
    }
`

const StyledTopBar = styled(Flex)`
    a {
        color: #333333;
    }

    a:visited {
        color: #333333;
    }
`


const FixFreeTrialBanner = styled(Flex)`
    width: 100%;
    background-color: #000000;
    position: relative;
    padding: 14px 0px;
    cursor: pointer;
    position: sticky;
    bottom: 0px;

    @media (max-width: 480px) {
        display: flex;
        position: sticky;
        bottom: 0px;
    }

`

export default App;
